import React, { useCallback } from 'react';
import { graphql } from 'gatsby';
import { useTheme } from '@material-ui/core/styles';
import { Container, Box } from '@material-ui/core';
import { useColorMode } from '@context/ColorModeContext';
import { SET_COLOR_MODE } from '@context/ColorModeContext/actions';
import { EntryContextProvider } from '@context/EntryContext';
import Layout from '@c/Layout';
import VisibilitySensor from '@c/VisibilitySensor';
import FeaturedContentGrid from '@c/FeaturedContentGrid';
import Pagination from '@c/Pagination';
import FormHandler from '@c/forms';

const SubscribePage = ({
  data: {
    cms: { subscribeMainPage, newslettersEntries, seomatic },
  },
  pageContext,
  ...props
}) => {
  const theme = useTheme(),
    [, setBackground] = useColorMode(),
    { currentPage, numPages } = pageContext,
    ctaUrl = `/${subscribeMainPage.uri}`;

  const handleOnVisible = useCallback(
    id => {
      setBackground({
        type: SET_COLOR_MODE,
        background: { color: theme.colors.bg },
        header: { mode: 'light', absolute: true },
        visibility: { visibleContentId: id },
      });
    },
    [setBackground, theme.colors.bg]
  );

  return (
    <EntryContextProvider entry={subscribeMainPage}>
      <Layout seomatic={seomatic}>
        <VisibilitySensor id={`puls-${currentPage}`} onVisible={handleOnVisible}>
          <FormHandler
            form={{
              handle: subscribeMainPage.subscribeForm[0].typeHandle,
            }}
            {...subscribeMainPage.subscribeForm[0]}
          />

          <Box mt={{ xs: 12, md: 19.5 }} mb={19.5}>
            <Container>
              <FeaturedContentGrid
                smallGrid
                intro={subscribeMainPage.text}
                introGridProps={{
                  xs: 12,
                  sm: 12,
                  lg: 12,
                  xl: 12,
                }}
                introProps={{
                  textAlign: 'center',
                  className: 'space-p-l-6 space-p-r-6 sm-space-m-b-8',
                }}
                ctaUrl={ctaUrl}
                entries={newslettersEntries}
              />

              {numPages > 1 && (
                <Box mt={{ xs: 19.5, md: 32 }}>
                  <Pagination pagePrefix={ctaUrl} currentPage={currentPage} pageCount={numPages} />
                </Box>
              )}
            </Container>
          </Box>
        </VisibilitySensor>
      </Layout>
    </EntryContextProvider>
  );
};

export default SubscribePage;

export const query = graphql`
  query($path: String!, $entriesIds: [cms_QueryArgument]!, $limit: Int!, $skip: Int!) {
    cms {
      subscribeMainPage: entry(section: "subscribe") {
        ... on cms_subscribe_subscribe_Entry {
          uri
          sectionHandle
          subscribeForm {
            ...subscribeFormFields
          }
          text
        }
      }
      newslettersEntries: entries(
        section: "newsletters"
        id: $entriesIds
        limit: $limit
        offset: $skip
        orderBy: "postDate desc"
      ) {
        ... on cms_newsletters_newsletters_Entry {
          id
          sectionHandle
          postDate
          uri
          featuredContent {
            ...featuredContentFields
          }
        }
      }
      seomatic(uri: $path) {
        ...seomaticFields
      }
    }
  }
`;
